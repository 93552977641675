export const HousingDetails = {
    Address: "",
    FullAddress: "",
    State: "AL",
    City: "",
    ZipCode: "",
    Price: "",
    NumberOfBathrooms: "",
    NumberOfBedrooms: "",
    SqrFt: "",
    CompensationType: "997",
    BrokerCommission: "",
    PropertyStatus: "",
    HasBasement: "",
    HasGarage: "",
    MLS: "",
    ListingUrl: "",
    ListingUUID: "",
    uid: ""
}
export const SearchParms = {
    Address: "",
    State: "AL",
    City: "",
    ZipCode: "",
    NumberOfBathrooms: "",
    NumberOfBedrooms: "",
    HasBasement: "",
    HasGarage: "",
    MLS: "",
    MinPrice: "",
    MaxPrice: ""
}

export const profileInfo = {
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    EmailAddress: "",
    uid: ""
}

export const CommissionArray = [
    // {"value": "3", "label": "3 - 4"},
    // {"value": "2", "label": "2 - 3"},
    // {"value": "0", "label": "0 - 1"},
    {"value": "997", "label": "Commission Percentage"},
    {"value": "998", "label": "Flat Fee"},
    {"value": "999", "label": "No Commission Offered"},
    
]

export const StatesArray = [
    {"value": "AL", "label": "Alabama", "fips": "01"},
    {"value": "AK", "label": "Alaska", "fips": "02"},
    {"value": "AZ", "label": "Arizona", "fips": "04"},
    {"value": "AR", "label": "Arkansas", "fips": "05"},
    {"value": "CA", "label": "California", "fips": "06"},
    {"value": "CO", "label": "Colorado", "fips": "08"},
    {"value": "CT", "label": "Connecticut", "fips": "09"},
    {"value": "DE", "label": "Delaware", "fips": "10"},
    {"value": "FL", "label": "Florida", "fips": "12"},
    {"value": "GA", "label": "Georgia", "fips": "13"},
    {"value": "HI", "label": "Hawaii", "fips": "15"},
    {"value": "ID", "label": "Idaho", "fips": "16"},
    {"value": "IL", "label": "Illinois", "fips": "17"},
    {"value": "IN", "label": "Indiana", "fips": "18"},
    {"value": "IA", "label": "Iowa", "fips": "19"},
    {"value": "KS", "label": "Kansas", "fips": "20"},
    {"value": "KY", "label": "Kentucky", "fips": "21"},
    {"value": "LA", "label": "Louisiana", "fips": "22"},
    {"value": "ME", "label": "Maine", "fips": "23"},
    {"value": "MD", "label": "Maryland", "fips": "24"},
    {"value": "MA", "label": "Massachusetts", "fips": "25"},
    {"value": "MI", "label": "Michigan", "fips": "26"},
    {"value": "MN", "label": "Minnesota", "fips": "27"},
    {"value": "MS", "label": "Mississippi", "fips": "28"},
    {"value": "MO", "label": "Missouri", "fips": "29"},
    {"value": "MT", "label": "Montana", "fips": "30"},
    {"value": "NE", "label": "Nebraska", "fips": "31"},
    {"value": "NV", "label": "Nevada", "fips": "32"},
    {"value": "NH", "label": "New Hampshire", "fips": "33"},
    {"value": "NJ", "label": "New Jersey", "fips": "34"},
    {"value": "NM", "label": "New Mexico", "fips": "35"},
    {"value": "NY", "label": "New York", "fips": "36"},
    {"value": "NC", "label": "North Carolina", "fips": "37"},
    {"value": "ND", "label": "North Dakota", "fips": "38"},
    {"value": "OH", "label": "Ohio", "fips": "39"},
    {"value": "OK", "label": "Oklahoma", "fips": "40"},
    {"value": "OR", "label": "Oregon", "fips": "41"},
    {"value": "PA", "label": "Pennsylvania", "fips": "42"},
    {"value": "RI", "label": "Rhode Island", "fips": "44"},
    {"value": "SC", "label": "South Carolina", "fips": "45"},
    {"value": "SD", "label": "South Dakota", "fips": "46"},
    {"value": "TN", "label": "Tennessee", "fips": "47"},
    {"value": "TX", "label": "Texas", "fips": "48"},
    {"value": "UT", "label": "Utah", "fips": "49"},
    {"value": "VT", "label": "Vermont", "fips": "50"},
    {"value": "VA", "label": "Virginia", "fips": "51"},
    {"value": "WA", "label": "Washington", "fips": "53"},
    {"value": "WV", "label": "West Virginia", "fips": "54"},
    {"value": "WI", "label": "Wisconsin", "fips": "55"},
    {"value": "WY", "label": "Wyoming", "fips": "56"}
]

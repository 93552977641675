import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="container-tos">
            <h1>Terms of Service</h1>

            <h2>I. Introduction</h2>
            <p>
                These are the Terms of Use (the “Terms”) for Buyer Brokers Network (“BBN”). These Terms apply when you visit our website
                at buyerbrokersnetwork.com and otherwise interact with us or use our platform (our “Services”). These Terms include terms regarding our subscription services.
                The Services are intended ONLY for users in the United States and their use is governed by US law.
            </p>

            <h2>II. Your Responsibilities</h2>
            <p>
                By using the information, tools, features, and functionality, including content and other services of BBN, you agree to be bound by these Terms whether you are a “Visitor”
                (meaning you are only browsing our website) or a “Registered User” (meaning you’ve registered for the Services). Please be aware that these Terms constitute a binding legal agreement
                between you and BBN. You agree you are responsible for your use of our Services and any resulting consequences. You may use our Services and interact with us only in compliance with these Terms and all applicable laws.
                Your use of our Services constitutes your acceptance of these Terms and our Privacy Policy. When using certain BBN Services, you may be subject to any additional terms that are otherwise made available from time to time.
                If you are accepting these terms on behalf of an entity, you represent, warrant, and agree that you have the authority to accept these Terms.
            </p>

            <h2>III. Eligibility Requirements</h2>
            <p>
                By accepting these Terms through your use of our Services, you certify that you are at least 18 years of age. If you are under the age of 18, you are not permitted to use our Services.
            </p>

            <h2>IV. Your Representations & Warranties to BBN</h2>
            <p>
                By using our Services, you represent, warrant, and agree:
            </p>
            <ol>
                <li>You meet all age and eligibility requirements expressed in these Terms;</li>
                <li>You are solely responsible for the accuracy, legality, and appropriateness of your use of the Services, including all data, files, and communications entered into the Services, as well as the use of downloadable assets generated from the Services; and</li>
                <li>You will only use our Services for lawful purposes.</li>
            </ol>

            <h2>V. Prohibited Uses</h2>
            <p>
                You agree not to use our Services:
            </p>
            <ol>
                <li>In any way that violates any applicable federal, state, local, or international law or regulation (including without limitation any laws regarding the export of data or software to and from the US or other countries);</li>
                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personal information, or otherwise;</li>
                <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any 'junk mail', 'chain letter', 'spam', or any other similar solicitation;</li>
                <li>To impersonate or attempt to impersonate BBN, a BBN employee, another user, or any other person or entity (including without limitation by using email addresses associated with any of the foregoing); or</li>
                <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm BBN or users of the Services, or expose them to liability.</li>
            </ol>
            <p>Additionally, you agree not to:</p>
            <ol>
                <li>Use the Services in any manner that could disable, overburden, damage, or impair the site, or interfere with any other party's use of the Services, including their ability to engage in real-time activities through the Services;</li>
                <li>Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services;</li>
                <li>Use any device, software, or routine that interferes with the proper working of the Services;</li>
                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, or any server, computer, or database connected to the Services, or attack the Services in any way; or</li>
                <li>Otherwise attempt to interfere with the proper working of the Services.</li>
            </ol>

            <h2>VI. Services and Availability</h2>
            <p>
                We reserve the right to withdraw or amend our Services in our sole discretion without notice. We will not be liable if for any reason all or any part of the Services are unavailable at any time or for any period.
                From time to time, we may restrict user access to some parts or the entirety of our Services. You are responsible for both:
            </p>
            <ol>
                <li>Making all arrangements necessary for you to have access to the Services; and</li>
                <li>Ensuring that all persons who access the Services through your internet connection are aware of these Terms of Use and comply with them. BBN reserves the right to investigate and take appropriate legal action against anyone who violates these Terms.</li>
            </ol>

            <h2>VII. Data Back Up</h2>
            <p>
                Content and data uploaded to the Services is backed up periodically, but BBN is not responsible for storing or retaining content, and we shall not be liable for any loss, deletion, or alteration of any posted content or data. Users should maintain a backup copy of any uploaded data.
            </p>

            <h2>VIII. Reliance on Information Posted</h2>
            <p>
                The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk.
                We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Services, or by anyone who may be informed of any of its contents.
                Our Services may include content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting Services.
                All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by BBN, are solely the opinions and the responsibility of the person or entity providing those materials.
                These materials do not necessarily reflect the opinion of BBN. We are not responsible or liable to you or any third party for the content or accuracy of any materials provided by any third parties.
            </p>

            <h2>IX. Registering for an Account</h2>
            <p>
                In order to use or access certain Services or features of the Services, you will be asked to register for a user account (an “Account”) and become a Registered User. By becoming a Registered User, you agree to:
            </p>
            <ol>
                <li>Provide accurate, current, and complete information about the Registered User during the registration process;</li>
                <li>Maintain and promptly update such information to keep it accurate, current, and complete;</li>
                <li>Maintain the security of your password and login information, and that you will not disclose your password or login information to any third party;</li>
                <li>Accept full responsibility for all use of any Account you register, and for any actions that arise from or take place using your Account, whether or not you have authorized such actions or use; and</li>
                <li>Immediately notify BBN of any unauthorized use of your Account.</li>
            </ol>

            <h2>X. License to Use of the Services</h2>
            <p>
                Subject to these Terms, upon creation of your Account, we grant you an exclusive, non-transferrable right and limited license to access and use the Services during your Subscription term. You acknowledge and agree that the Services are provided under license and not sold to you.
                You do not acquire any ownership interest in the Services under these Terms or any other rights thereto other than to use the Services in accordance with the license granted, and subject to all terms, conditions, and restrictions under these Terms.
            </p>

            <h2>XI. Termination of Access</h2>
            <p>
                Failure to abide by the above section constitutes a breach of these Terms, which may result in immediate termination of your Account or other access to the Services.
                BBN maintains the right to suspend or disable your access to the Services and any Account you may have created, or terminate these Terms at our sole discretion and without prior notice to you if you breach the Terms or if BBN otherwise determines such action is warranted.
                BBN reserves the right to revoke your access to and use of the Services at any time with or without cause.
            </p>

            <h2>XII. Your Cancellation of Your Account or Use of the Services</h2>
            <p>
                You may cancel your Account or your access to the Services at any time by following the instructions within these Terms. Upon cancellation of your Account, BBN may:
            </p>
            <ol>
                <li>Retain and share your information and content indefinitely if allowable by law; and</li>
                <li>Retain, use, and continue to show information about your use of the Services. Please note that cancellation of an Account as a Registered User is subject to our Payment Terms.</li>
            </ol>

            <h2>XIII. Subscription & Payment Terms</h2>
            <p>
                PLEASE READ THESE TERMS CAREFULLY AND IN FULL. THEY CONTAIN CERTAIN CONDITIONS AND RESTRICTIONS ON THE AVAILABILITY AND USE OF PAID SUBSCRIPTIONS. CERTAIN PAID SUBSCRIPTIONS CONTINUE INDEFINITELY AND YOU WILL CONTINUE TO BE CHARGED THE RECURRING SUBSCRIPTION FEE AS ADVERTISED AND AS AMENDED FROM TIME TO TIME UNTIL YOU CANCEL YOUR ACCOUNT.
                BBN provides exclusive content, information, and services through its paid subscription service (the “Subscription”). By creating an Account and purchasing a Subscription, you represent, warrant, and agree that you have the authority to purchase products on behalf of you or the entity you represent.
            </p>

            <h2>Subscription Durations</h2>
            <p>
                Subscriptions are either set for a monthly or annual term. Upon signing up for a Subscription, fees will be pro-rated until the following month or year. All billing dates occur on the first of the month.
            </p>

            <h2>Billing Information and Payment</h2>
            <p>
                You authorize us to continue to charge your payment method for the price of your Subscription plus any applicable taxes unless and until you cancel. All billing information provided must be truthful and accurate. Providing any untruthful or inaccurate information constitutes a breach of these Terms and may result in cancellation of your Subscription and status as a Registered User.
            </p>

            <h2>Payment Failures</h2>
            <p>
                If charges for which you are responsible fail at the time payment is due, we may continue to attempt to charge your payment method until such time as your payment method can successfully be charged, and you will remain responsible for the payment of any such charges. You may be responsible for the costs associated with our efforts to collect amounts due to the extent permitted by applicable laws.
            </p>

            <h2>Subscription Price Changes</h2>
            <p>
                We reserve the right to make changes to your Subscription price, frequency, or date of charge. If the cost of your Subscription increases, you will be provided with written notice prior to the billing period in which the change will become effective, and you must opt into the price increase in order to continue to have access to your Subscription following the end of your current billing period.
            </p>

            <h2>Auto-renew</h2>
            <p>
                Your Subscription with us will continue to auto-renew until terminated. Your BBN membership is effective for the term covered by your payment and continues upon your payment of the renewal. The renewal cost for your Subscription will automatically be charged at the then-current rate for your Subscription, currently priced to the checking account, credit card, or other billing source authorized by you.
            </p>

            <h2>Auto-renewal Cancellation</h2>
            <p>
                To avoid the automatic renewal of your Subscription, you must cancel your subscription at least twenty-four (24) hours prior to the end of your current billing cycle. If you cancel less than 24 hours before the end of your current billing cycle, your subscription will continue as scheduled, and your cancellation will take effect at the end of the next billing cycle.
                If you cancel your Subscription prior to the end of your Subscription, you will be entitled to continue to access your Subscription through to the end of your current Subscription period. At the end of your subscription period, you will immediately lose all access to any content or features provided through your Subscription.
            </p>

            <h2>Termination By BBN</h2>
            <p>
                BBN reserves the right to refuse or cancel any Subscription for any reason, including for violation of these Terms. No Refunds. All purchases made are non-refundable. Promotions and Trials. We may offer special promotional pricing and trials for our Subscriptions (each a “Promotional Subscription”) which may be subject to additional terms from these Terms. Any additional terms will be disclosed to you at or prior to your Promotional Subscription sign-up. At the end of your Promotional Subscription term, your subscription will automatically renew at the price and for the duration disclosed to you at the signup of your Promotional Subscription.
            </p>

            <h2>Third Party Promotions & Offerings</h2>
            <p>
                From time to time, we may also offer special promotional plans, content, or memberships, including offerings of third-party products or services in conjunction with or through our Services. We are not responsible for the products or services provided by such third parties. Modification to Subscription Content. We reserve the right to modify the features and content we provide as part of our Paid Subscriptions from time to time and for any reason.
            </p>

            <h2>XIV. Intellectual Property</h2>
            <p>
                Service Content, Software, and Trademarks. You acknowledge and agree that our Services may contain content or features (“Service Content”) that are protected by copyright, patent, trademark, trade secret, or other proprietary rights and laws. Except as expressly authorized by BBN, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute, or create derivative works based on our Services or Service Content, in whole or in part, except that the foregoing does not apply to any your own feedback that you legally upload to our Services. The BBN name and logos are trademarks and Service marks of BBN (collectively the “BBN Trademarks”). Other company, product, and Service names and logos used and displayed via our website may be trademarks or Service marks of their respective owners who may or may not endorse, be affiliated with, or connected to BBN. Nothing in these Terms or in our Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of BBN Trademarks displayed through our Services without our prior written permission in each instance. All goodwill generated from the use of BBN Trademarks will inure to our exclusive benefit.
            </p>

            <h2>Feedback Transmitted Through Our Services</h2>
            <p>
                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, and other information (“Feedback”) provided by you to BBN is non-confidential, and BBN will be entitled to the unrestricted use and dissemination of this Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You acknowledge and agree that BBN may preserve content and may also disclose feedback or content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws, or government requests; (b) enforce these Terms; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of BBN, its users, and the public. You understand that the technical processing and transmission of our Services, including your content, may involve: (i) transmissions over various networks; and (ii) changes to conform and adapt to technical requirements of connecting networks or devices.
            </p>

            <h2>XV. Linking to the Website</h2>
            <p>
                You may link to our website, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express consent.
            </p>

            <h2>XVI. Third-Party Services</h2>
            <p>
                Our Services may provide links or other access to other third-party sites and resources on the internet. BBN has no control over such sites and resources, and BBN is not responsible for and does not endorse such sites and resources. Our Services allow third parties to upload information to our Services, including images that may include the trademarks or intellectual property of third parties. BBN is not responsible for any user uploads containing this content.
            </p>

            <h2>XVII. Digital Millennium Copyright Act</h2>
            <p>
                We respect the intellectual property of others and ask users of our Services to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement or that your intellectual property rights have been otherwise violated on our Services, notify us by following this section. We will process and investigate notices of alleged infringement and will take appropriate actions consistent with the U.S. Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to our Copyright Agent at info@buyerbrokersnetwork.com (Subject line: “DMCA Takedown Request”).
            </p>

            <h2>To be effective, the notification must be in writing and contain the following information:</h2>
            <ul>
                <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                <li>A description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                <li>A description of where the material that you claim is infringing is located on our Services or otherwise within our Service with enough detail that we may find it;</li>
                <li>Your address, telephone number, and email address;</li>
                <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;</li>
                <li>A statement by you made under penalty of perjury that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
            </ul>

            <h2>Counter-Notice</h2>
            <p>
                If you believe that your content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law to upload and use the content, you may send a written counter-notice containing the following information to the Copyright Agent:
            </p>
            <ul>
                <li>Your physical or electronic signature;</li>
                <li>Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
                <li>A statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and</li>
                <li>Your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court located within the Western District of Michigan, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>
            </ul>

            <h2>Repeat Infringer Policy</h2>
            <p>
                In accordance with the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate circumstances and at our sole discretion, users who are deemed to be repeat infringers. We may also, at our sole discretion, limit access to our Services and/or terminate any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.
            </p>

            <h2>XVIII. Communications</h2>
            <p>
                As part of your use of our Services, you consent to receive electronic notifications from BBN. You may opt-out of receiving certain notifications from BBN by completing the opt-out process provided in each email message. By opting out, you understand that we may not be able to communicate certain information to you. Please note we may still contact you regarding certain transactional announcements or notifications even if you have opted out from other messages.
            </p>

            <h2>XIX. Third-Party Advertising & Marketing</h2>
            <p>
                BBN may employ third-party advertising and marketing to deliver ads, information, and other promotions to you both through our Services and other mechanisms. By agreeing to our Terms, you agree to receive such advertising and marketing from BBN and our partners. If you do not wish to receive such advertising, you may opt out with the instructions provided within the communication. BBN may compile and release information regarding you and your use of our Services on an anonymous basis as part of a customer profile or similar report or analysis. It is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with through our Services.
            </p>

            <h2>XX. Indemnity and Release</h2>
            <p>
                You agree to release, indemnify, and hold harmless BBN, its affiliates, and its and their respective officers, employees, directors, members, and agents from any and all losses, damages, expenses (including reasonable attorneys’ fees), rights, claims, actions of any kind, and injury (including death) arising out of or relating to your use of Services and interactions with us, your violation of these Terms, or your violation of any rights of another.
            </p>

            <h2>XXI. Disclaimer of Warranties Related to our Services</h2>
            <p>
                YOUR USE OF OUR SERVICES AND YOUR INTERACTIONS WITH US IS AT YOUR SOLE RISK. OUR SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. BBN EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. BBN MAKES NO WARRANTY THAT: (I) OUR SERVICES OR OTHER INTERACTIONS WILL MEET YOUR REQUIREMENTS; (II) OUR SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR SERVICES WILL BE ACCURATE OR RELIABLE; OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES WILL MEET YOUR EXPECTATIONS.
            </p>

            <h2>XXII. Limitation of Liability</h2>
            <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT BBN WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF BBN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE OUR SERVICES OR ANY CONTENT; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED, OR MESSAGES RECEIVED, OR TRANSACTIONS ENTERED INTO THROUGH OR FROM OUR SERVICES; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT, TRANSMISSIONS, OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICES; OR (V) ANY OTHER MATTER RELATING TO OUR SERVICES OR CONTENT. IN NO EVENT WILL BBN’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID BBN IN THE LAST SIX (6) MONTHS OR, IF GREATER, ONE HUNDRED U.S. DOLLARS (US $100). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF OUR SERVICES OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF OUR SERVICES.
            </p>

            <h2>XXIII. General</h2>
            <p>
                International Use and Export Controls. Any software available in connection with our Services and the transmission of applicable data, if any, is subject to United States export controls. No software may be downloaded as part of our Services or otherwise exported or re-exported in violation of U.S. export laws. Downloading or using any software is at your sole risk. Recognizing the global nature of the Internet, you agree to comply with all local rules and laws regarding your use of our Services, including as it concerns online conduct and acceptable content.
            </p>

            <h2>Modification</h2>
            <p>
                We may modify these Terms at any time. We will post the changes on our website or platform with the effective date and notify you of any material changes. Your continued use of our Services or continued interactions with us after the date of any such changes become effective constitutes your acceptance of these Terms.
            </p>

            <h2>Governing Law</h2>
            <p>
                These Terms will be governed by the laws of Michigan without regard to conflict of law provisions. With respect to any disputes not subject to the dispute resolution procedures set forth above, you and BBN agree to submit to the personal and exclusive jurisdiction of the local courts located in [City], MI, and the federal courts located in the United States District Court for the Western District of Michigan. BBN may assign or transfer these Terms in whole or in part without restriction.
            </p>

            <h2>No Waiver</h2>
            <p>
                The failure of BBN to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
            </p>

            <h2>Severability</h2>
            <p>
                In case any provision of these Terms is found by a court of competent jurisdiction to be invalid, the validity, legality, and enforceability of the remaining provisions will not be affected and remain in full effect. The parties agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision.
            </p>

            <h2>Claim Limitations</h2>
            <p>
                You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of the use of the website or these Terms must be filed within one (1) year after such claim or cause of action arose, or be forever barred.
            </p>

            <h2>XXIV. Contact Us</h2>
            <p>
                - Email us at: info@buyerbrokersnetwork.com
                - Mailing Address:
                Buyer Brokers Network
                [Company Address]
            </p>
        </div>
    );
};

export default TermsOfService;

import { useState, useEffect } from 'react';
import { Sleep, StateSelection, isValidUSZipCode, CommissionSelection } from './Misc';
import { HousingDetails } from './Objs';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './config/fb-config';
import { savePropertyInfoN4j } from './config/neo4j-db';
import Cookies from 'js-cookie'
import { formatCurrency, addCommasToNumber } from './Misc';
import housingData from './housing_details_10000.json';
import './EditListing.css'; // Make sure to import the CSS file

export default function EditListing() {
  const [houseInfo, setHouseInfo] = useState(HousingDetails);
  const [readonly, setReadOnly] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const savedStateSelection = Cookies.get('SelectedStateCreateListing')
    setHouseInfo((prev) => ({
      ...prev,
      'State': savedStateSelection
    }))
    if (!location.state?.info.ListingUUID || location.state?.info === undefined) {
      setHouseInfo((prev) => ({
        ...prev,
        ListingUUID: uuidv4(),
      }));
    } else {
      setHouseInfo(location.state?.info);
    }

    checkEditStatus();
  }, []);

  const checkEditStatus = () => {
    if (location.state?.info.uid === undefined) {
      setReadOnly(false);
    } else {
      if (auth?.currentUser?.uid !== undefined) {
        setReadOnly(auth?.currentUser?.uid !== location.state?.info.uid ? true : false);
      }
    }
  };

  const updateInfoString = (e) => {
    const { name, value } = e.target;
    setHouseInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(name, value)
    if(houseInfo.CompensationType !== "998")
    {
      // console.log("changing commission structure zero out flat fee")
      setHouseInfo((prev) => ({
        ...prev,
        BrokerCommission: 0
      }))
      // console.log(houseInfo)
    }

    if(name === "State")
    {
      const expirationDate = new Date()
      expirationDate.setFullYear(expirationDate.getFullYear() + 100) // 100 years in the future
      Cookies.set('SelectedStateCreateListing',value, { expires: expirationDate })
      // console.log(await getCitiesByState('39'))
    }
    
    setFullAddress();
  };

  const updateInfoNumber = (e) => {
    const { name, value } = e.target;
    if(!isNaN(value))
    {
      setHouseInfo((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
      setFullAddress();
    }
  };

  const updateInfoBool = (e) => {
    const { name, checked } = e.target;
    setHouseInfo((prev) => ({
      ...prev,
      [name]: checked,
    }));
    setFullAddress();
  };

  const updateInfoFloat = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^\d.-]/g, '');
    const match = sanitizedValue.match(/^-?\d*(\.\d{0,1})?/);
    const validValue = match ? match[0] : '';

    setHouseInfo((prev) => ({
      ...prev,
      [name]: validValue,
    }));
  };

  const setFullAddress = () => {
    try {
      const value = `${houseInfo.Address} ${houseInfo.City}, ${houseInfo.State} ${houseInfo.ZipCode}`;
      setHouseInfo((prev) => ({
        ...prev,
        FullAddress: value,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const UpdateListing = async () => {
    try{
      if (!houseInfo.uid) {
        houseInfo.uid = auth?.currentUser?.uid;
      }
      await savePropertyInfoN4j(houseInfo);
    } catch (e){
      console.log(e)
    }
  }

  const GenerateListing = async (houseInfo) => {
    await savePropertyInfoN4j(houseInfo);
  };
  // console.log(houseInfo)
  return (
    <div className="container">
      <form className="edit-form">
        <div className="form-group">
          <label htmlFor="address">Address</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.Address}</p>
          ) : (
            <input
              type="text"
              id="address"
              name="Address"
              placeholder="Enter address"
              onChange={updateInfoString}
              value={houseInfo.Address}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.City}</p>
          ) : (
            <input
              type="text"
              id="city"
              name="City"
              placeholder="Enter city"
              onChange={updateInfoString}
              value={houseInfo.City}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="state">State</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.State}</p>
          ) : (
            <StateSelection
              className="state-selection"
              name="State"
              onChange={updateInfoString}
              selectedState={houseInfo.State}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="zipcode">Zip Code</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.ZipCode}</p>
          ) : (
            <input
              type="number"
              id="zipcode"
              name="ZipCode"
              placeholder="Enter zip code"
              onChange={updateInfoNumber}
              value={houseInfo.ZipCode}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          {readonly ? (
            <p className="styled-text">{formatCurrency(houseInfo.Price)}</p>
          ) : (
            <input
              type="number"
              id="price"
              name="Price"
              placeholder="Enter price"
              onChange={updateInfoNumber}
              value={houseInfo.Price}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bedrooms">Number of Bedrooms</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.NumberOfBedrooms}</p>
          ) : (
            <input
              type="text"
              id="bedrooms"
              name="NumberOfBedrooms"
              placeholder="Enter number of bedrooms"
              onChange={updateInfoFloat}
              value={houseInfo.NumberOfBedrooms}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bathrooms">Number of Bathrooms</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.NumberOfBathrooms}</p>
          ) : (
            <input
              type="text"
              id="bathrooms"
              name="NumberOfBathrooms"
              placeholder="Enter number of bathrooms"
              onChange={updateInfoFloat}
              value={houseInfo.NumberOfBathrooms}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="sqft">Square Feet</label>
          {readonly ? (
            <p className="styled-text">{addCommasToNumber(houseInfo.SqrFt)}</p>
          ) : (
            <input
              type="text"
              id="sqft"
              name="SqrFt"
              placeholder="Enter square feet"
              onChange={updateInfoNumber}
              value={houseInfo.SqrFt}
            />
          )}
        </div>
        <div className="form-group checkbox-group">
          <label htmlFor="basement">Basement</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.HasBasement ? 'Yes' : 'No'}</p>
          ) : (
            <input
              type="checkbox"
              id="basement"
              name="HasBasement"
              onChange={updateInfoBool}
              checked={houseInfo.HasBasement}
            />
          )}
        </div>
        <div className="form-group checkbox-group">
          <label htmlFor="garage">Garage</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.HasGarage ? 'Yes' : 'No'}</p>
          ) : (
            <input
              type="checkbox"
              id="garage"
              name="HasGarage"
              onChange={updateInfoBool}
              checked={houseInfo.HasGarage}
            />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="compensation-type">Broker Compensation </label>
          {readonly ? (
            // <p className="styled-text">{houseInfo.BrokerCommission}</p>
            <p className="styled-text">{houseInfo.BrokerCommission === "999"
            ? "No Compensation Offered"
            : houseInfo.BrokerCommission === "998"
              ? "Flat Fee"
              : houseInfo.BrokerCommission + " - " + (Number(houseInfo.BrokerCommission) + 1)}</p>
          ) : (
            <CommissionSelection
              id="compensation-type"
              className="broker-commission"
              name="CompensationType"
              onChange={updateInfoString}
              selectedCommision={houseInfo.CompensationType}
            />
          )}
        </div>
        {/* <div className="form-group">
          <label htmlFor="listing-url">Listing URL</label>
          {readonly ? (
            <p className="styled-text">{houseInfo.ListingUrl ? houseInfo.ListingUrl : "Not Provided"}</p>
          ) : (
            <input
              type="text"
              id="listing-url"
              name="ListingUrl"
              placeholder="Enter listing URL"
              onChange={updateInfoString}
              value={houseInfo.ListingUrl}
            />
          )}
        </div> */}
        {houseInfo.CompensationType === "998" || houseInfo.CompensationType === "997" ?
        <div className="form-group">
          {houseInfo.CompensationType === "998"?
            <label htmlFor="flat-fee">Flat Fee ($)</label>
          :
            <label htmlFor="flat-fee">Compensation (%)</label>
          }
          
          {readonly ? (
              <p className="styled-text">{houseInfo.BrokerCommission}</p>
            ) : (
              <input
                type="number"
                id="broker-commission"
                className='broker-commission'
                name="BrokerCommission"
                placeholder="Enter Compensation"
                onChange={updateInfoFloat}
                value={houseInfo.BrokerCommission}
              />
            )}
        </div>
        :
        ""
        }
        {/* <div className="form-group">
          <label htmlFor="mls">MLS</label>
          <input
            type="text"
            id="mls"
            name="MLS"
            placeholder="Enter MLS"
            onChange={updateInfoString}
            value={houseInfo.MLS}
            disabled={readonly}
          />
        </div> */}
        {!readonly && (
          <button
            type="button"
            className="save-button"
            onClick={async () => {
              if (isValidUSZipCode(houseInfo.ZipCode)) {
                setFullAddress();
                if (UpdateListing()) {
                  await Sleep(1000);
                  navigate('/');
                } else {
                  alert('Error adding to DB');
                }
              } else {
                alert('Invalid Zip Code');
              }
            }}
          >
            Save
          </button>
        )}
        {/* {!readonly && (
          <button
            type="button"
            className="save-button mt-5"
            onClick={async () => {
              housingData.forEach(async (propInfo) => {
                savePropertyInfoN4j(propInfo)
                // savePropertyInfoN4j(housingData[0])
                await Sleep(1000)
              })
                
              
            }}
          >
            Generate Listings
          </button>
        )} */}
      </form>
    </div>
  );
}

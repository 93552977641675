import React, { useState } from 'react';
import { CreateAccount, CreateProfile } from './Misc';
import { useNavigate } from 'react-router-dom'
import './SignUp.css'; // Import the CSS file
import { auth } from './config/fb-config'


export default function SignUp() {
  const accountInfo = {
    email: "",
    password: "",
    passwordConf: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    uid: ""
  };

  const [authInfo, setAuthInfo] = useState(accountInfo)
  const [error, setError] = useState()
  const navigate = useNavigate();

  const updateAccountInfo = (e) => {
    const { name, value } = e.target;
    setAuthInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const setUid = async (uid) =>{
      setAuthInfo((prev) => ({
      ...prev,
      'uid': uid
    }))
  }
 
  return (
    <div className="signup-container">
      <div className="signup-form">
        <div>
          <input
            type="text"
            placeholder="First Name"
            className="signup-input"
            name="firstName"
            onChange={updateAccountInfo}
            value={authInfo.firstName}
            maxLength="50"
          />
        </div>
        <div className='mt-2'>
          <input
            type="text"
            placeholder="Last Name"
            className="signup-input"
            name="lastName"
            onChange={updateAccountInfo}
            value={authInfo.lastName}
            maxLength="50"
          />
        </div>
        <div className='mt-2'>
          <input
            type="email"
            placeholder="Email Address"
            className="signup-input"
            name="email"
            onChange={updateAccountInfo}
            value={authInfo.email}
            maxLength="50"
          />
        </div>
        <div className='mt-2'>
          <input
            type="text"
            placeholder="Phone Number"
            className="signup-input"
            name="phoneNumber"
            onChange={updateAccountInfo}
            value={authInfo.phoneNumber}
            maxLength="50"
          />
        </div>
        <div className='mt-2'>
          <input
            type="password"
            placeholder="Password"
            className="signup-input"
            name="password"
            onChange={updateAccountInfo}
            value={authInfo.password}
            maxLength="50"
          />
        </div>
        <div className='mt-2'>
          <input
            type="password"
            placeholder="Confirm Password"
            className="signup-input"
            name="passwordConf"
            onChange={updateAccountInfo}
            value={authInfo.passwordConf}
            maxLength="50"
          />
        </div>
        {error?
          <div>
            <p className='styled-text-error mt-2'>{error}</p>
          </div>
        :
          ""
        }
        <div className="signup-button">
          <button onClick={async () => {
           console.log(auth.currentUser.uid,authInfo)
           await CreateProfile(authInfo)
           const err = await CreateAccount(authInfo) 
           await setUid(auth.currentUser?.uid)
           if(err){
             setError(err.code)
           } 
           else
           {
          //   // console.log("creating profile")
             await CreateProfile(authInfo)
            //  await CreateProfile({firstName: 'Annie', lastName: 'Newborne', phoneNumber: '9372877417', uid: `${auth.currentUser.uid}`})
             navigate('/')
           }
           
          }}>Sign Up</button>
        </div>
        {/* <div className="signup-button">
          <button onClick={async () => {
          
          //   // console.log("creating profile")
            //  await CreateProfile(authInfo)
             await CreateProfile({firstName: 'Carlos', lastName: 'Gray', phoneNumber: '9378381888', uid: `${auth.currentUser.uid}`})
             navigate('/')
           
          }}>Create Profile</button>
        </div> */}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AdvSearchN4j, AdvSearchN4jNotLoggedIn, getCurrentProfileN4j } from './config/neo4j-db';
import { SearchParms } from './Objs';
import { StateSelection, addCommasToNumber, prepareSMSMsgAndSend, formatCurrency } from './Misc';
import { auth } from './config/fb-config';
import Cookies from 'js-cookie';
import './Search.css';

function Search() {
  const [listings, setListings] = useState([]);
  const [profileInfo, setProfileInfo] = useState()
  const [searchTerms, setSearchTerms] = useState(SearchParms);
  const [showCompensation, setShowCompensation] = useState({});

  useEffect(() => {
    const savedStateSelection = Cookies.get('SelectedStateSearch');
    setSearchTerms((prev) => ({
      ...prev,
      State: savedStateSelection ? savedStateSelection : 'AL',
    }));
    getProfileInfo()
  }, []);
  const getProfileInfo = async ()=>{
    if(auth?.currentUser?.uid)
    {
      // const profileInfo = await getCurrentProfileN4j(auth.currentUser.uid)
      // console.log(profileInfo)
      // setProfileInfo(profileInfo[0])
    }
  }
  const updateInfoString = async (e) => {
    const { name, value } = e.target;
    setSearchTerms((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'State') {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 100); // 100 years in the future
      Cookies.set('SelectedStateSearch', value, { expires: expirationDate });
    }
  };

  const updateInfoNumber = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      setSearchTerms((prev) => ({
        ...prev,
        [name]: value ? Number(value) : '',
      }));
    }
  };

  const updateInfoFloat = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^\d.-]/g, '');
    const match = sanitizedValue.match(/^-?\d*(\.\d{0,1})?/);
    const validValue = match ? match[0] : '';

    setSearchTerms((prev) => ({
      ...prev,
      [name]: validValue,
    }));
  };

  const updateInfoBool = (e) => {
    const { name, checked } = e.target;
    setSearchTerms((prev) => ({
      ...prev,
      [name]: checked ? true : '',
    }));
  };

  const toggleCompensation = (index) => {
    setShowCompensation((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const searchListings = async () => {
    try {
      const docs = auth.currentUser ? await AdvSearchN4j(searchTerms) : await AdvSearchN4jNotLoggedIn(searchTerms);
      setListings(docs);
    } catch (e) {
      console.error(e);
    }
  };
  // console.log(profileInfo)
  // console.log(listings)
  return (
    <>
      <div className="container">
        <form className="search-form">
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="Address"
              placeholder="Enter address"
              onChange={updateInfoString}
              value={searchTerms.Address}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="City"
              placeholder="Enter city"
              onChange={updateInfoString}
              value={searchTerms.City}
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <StateSelection
              className="state-selection"
              name="State"
              onChange={updateInfoString}
              selectedState={searchTerms.State}
            />
          </div>
          <div className="form-group">
            <label htmlFor="zipcode">Zip Code</label>
            <input
              type="number"
              id="zipcode"
              name="ZipCode"
              placeholder="Enter zip code"
              onChange={updateInfoNumber}
              value={searchTerms.ZipCode}
            />
          </div>
          <div className="form-group">
            <label htmlFor="min-price">Minimum Price</label>
            <input
              type="number"
              id="min-price"
              name="MinPrice"
              placeholder="Enter minimum price"
              onChange={updateInfoNumber}
              value={searchTerms.MinPrice}
            />
          </div>
          <div className="form-group">
            <label htmlFor="max-price">Maximum Price</label>
            <input
              type="number"
              id="max-price"
              name="MaxPrice"
              placeholder="Enter maximum price"
              onChange={updateInfoNumber}
              value={searchTerms.MaxPrice}
            />
          </div>
          <div className="form-group">
            <label htmlFor="bedrooms">Number of Bedrooms</label>
            <input
              type="number"
              id="bedrooms"
              name="NumberOfBedrooms"
              placeholder="Enter number of bedrooms"
              onChange={updateInfoFloat}
              value={searchTerms.NumberOfBedrooms}
            />
          </div>
          <div className="form-group">
            <label htmlFor="bathrooms">Number of Bathrooms</label>
            <input
              type="number"
              id="bathrooms"
              name="NumberOfBathrooms"
              placeholder="Enter number of bathrooms"
              onChange={updateInfoFloat}
              value={searchTerms.NumberOfBathrooms}
            />
          </div>
          <div className="form-group checkbox-group">
            <label htmlFor="basement">Basement</label>
            <input
              type="checkbox"
              id="basement"
              name="HasBasement"
              onChange={updateInfoBool}
              checked={searchTerms.HasBasement}
            />
          </div>
          <div className="form-group checkbox-group">
            <label htmlFor="garage">Garage</label>
            <input
              type="checkbox"
              id="garage"
              name="HasGarage"
              onChange={updateInfoBool}
              checked={searchTerms.HasGarage}
            />
          </div>
          <button type="button" className="search-button" onClick={searchListings}>
            Search
          </button>
        </form>
      </div>

      <div className="results">
        {listings ? (
          <div className="record-count">Records Found: {listings.length}</div>
        ) : (
          <div className="record-count">No Records Found</div>
        )}
        {listings &&
          listings.map((info, i) => (
            <div key={i} className="listing">
                <div className="listing-details">
                  {auth.currentUser && <div className="location">{info.Address.split(',')[0]}</div>}
                  <div className="location">
                    <div>{info.City},</div>
                    <div>{info.State}</div>
                    <div>{info.ZipCode}</div>
                  </div>
                  {auth.currentUser && (
                    <>
                      <div className="additional-details">
                        <div>{addCommasToNumber(info.SqrFt)} sqft</div>
                        <div>{info.NumberOfBedrooms} bd</div>
                        <div>{info.NumberOfBathrooms} ba</div>
                      </div>
                      <div className="additional-details">
                        <div>Basement: {info.HasBasement ? 'Yes' : 'No'}</div>
                        <div>Garage: {info.HasGarage ? 'Yes' : 'No'}</div>
                      </div>
                    </>
                  )}
                  {auth.currentUser && (
                    <div className="additional-details">Price: {formatCurrency(info.Price)}</div>
                  )}
                  <div className="additional-details">
                    
                    {showCompensation[i] || auth.currentUser?.uid === info?.uid? (
                      <>
                        Buyer Broker Compensation:
                        <span>
                          {info.CompensationType === '999'
                            ? 'No Compensation Offered'
                            : info.CompensationType === '998'
                            ? formatCurrency(info.BrokerCommission)
                            : info.CompensationType === '997'
                            ? Number(info.BrokerCommission) + '%' : "N/A"}
                        </span>
                      </>
                    ) : (
                      <button className='btn-primary' onClick={() => {
                          toggleCompensation(i)
                          if(auth.currentUser)
                          {
                            prepareSMSMsgAndSend(info)
                          }
                        }
                      }>Request Compensation</button>
                    )}
                  </div>
                  
                  <div>
                    {auth.currentUser?.uid === info?.uid?
                      <>
                        <Link
                          to="/CreateListing"
                          state={{ info: info }}
                          style={{ pointerEvents: auth.currentUser ? '' : 'none', textDecoration: 'none' }}
                        >
                          <div className='btn-primary mt-3'>Edit My Listing</div>
                        </Link>
                      </>
                    :
                      ""
                    }
                  </div>
                </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default Search;

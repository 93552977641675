// import { auth } from './fb-config'

const neo4j = require('neo4j-driver')
const URI = process.env.REACT_APP_NEO4J_URI
const USER = process.env.REACT_APP_NEO4J_USERNAME
const PASSWORD = process.env.REACT_APP_NEO4J_PASSWORD
// const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
// const db_session = db_driver.session({ database: 'neo4j'})

const saveInfoQuery = `
// Ensure the State exists or create it
MERGE (s:State {Name: $info.State})
WITH s
// Ensure the City exists or create it and link it to the State
MERGE (c:City {Name: $info.City})
MERGE (c)-[:IN]->(s)
WITH s, c
// Ensure the ZipCode exists or create it and link it to the City
MERGE (z:ZipCode {ZipCode: $info.ZipCode})
MERGE (z)-[:IN]->(c)
WITH s, c, z
// Merge the Address node
MERGE (a:Address {ListingUUID: $info.ListingUUID})
ON CREATE SET 
    a.Address = $info.Address,
    a.FullAddress = $info.FullAddress,
    a.City = $info.City,
    a.State = $info.State,
    a.ZipCode = $info.ZipCode,
    a.Price = toFloat($info.Price),
    a.NumberOfBathrooms = $info.NumberOfBathrooms,
    a.NumberOfBedrooms = $info.NumberOfBedrooms,
    a.SqrFt = $info.SqrFt,
    a.BrokerCommission = $info.BrokerCommission,
    a.CompensationType = $info.CompensationType,
    a.PropertyStatus = $info.PropertyStatus,
    a.HasBasement = toBoolean($info.HasBasement),
    a.HasGarage = toBoolean($info.HasGarage),
    a.ListingUrl = $info.ListingUrl,
    a.MLS = $info.MLS,
    a.uid = $info.uid
ON MATCH SET 
    a.Address = $info.Address,
    a.FullAddress = $info.FullAddress,
    a.City = $info.City,
    a.State = $info.State,
    a.ZipCode = $info.ZipCode,
    a.Price = toFloat($info.Price),
    a.NumberOfBathrooms = $info.NumberOfBathrooms,
    a.NumberOfBedrooms = $info.NumberOfBedrooms,
    a.SqrFt = $info.SqrFt,
    a.BrokerCommission = $info.BrokerCommission,
    a.CompensationType = $info.CompensationType,
    a.PropertyStatus = $info.PropertyStatus,
    a.HasBasement = toBoolean($info.HasBasement),
    a.HasGarage = toBoolean($info.HasGarage),
    a.ListingUrl = $info.ListingUrl,
    a.MLS = $info.MLS,
    a.uid = $info.uid
MERGE (a)-[:IN]->(z)
RETURN a, z, c, s
`

// ,
//   MLS: $info.MLS,
//   ListingURL: $info.ListingURL

export const savePropertyInfoN4j = async (propertyInfo) => {
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        // console.log(propertyInfo)
        // console.log(saveInfoQuery)
        await db_driver.executeQuery(saveInfoQuery, {info: propertyInfo } )
    }catch(e){
    console.error(e)
}
    await db_driver.close()
    
}

const saveUserQuery = `
    MERGE (usersRoot:Users {name: 'Users'})

    MERGE (user:User {uid: $user.uid})
    MERGE (usersRoot)-[:HAS_USER]->(user)
    MERGE (profile:Profile {uid: $user.uid})
    SET profile.FirstName = $user.firstName,
        profile.LastName = $user.lastName,
        profile.EmailAddress = $user.emailAddress,
        profile.PhoneNumber = $user.phoneNumber
    MERGE (user)-[:HAS_PROFILE]->(profile)
  `


export const saveUserInfoN4j = async (userInfo) => {
    // console.log(userInfo)
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        // console.log(propertyInfo)
        // console.log(saveInfoQuery)
        await db_driver.executeQuery(saveUserQuery, {user: userInfo } )
    }catch(e){
        console.error(e)
    }
    await db_driver.close()
    
}

const getAllAddressesQuery = `
MATCH (a:Address)
RETURN a`

export const getCurrentListingsN4j = async () => {
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
       
        const { records } = await db_driver.executeQuery(getAllAddressesQuery)
        const housingDetailsList = records.map(record => {
            const addressNode = record.get('a').properties; // Directly accessing properties
      
            // Map the properties to the HousingDetails structure, with default values if any property is missing
            return {
              Address: addressNode.Address || "",
              FullAddress: addressNode.FullAddress || "",
              State: addressNode.State || "",
              City: addressNode.City || "",
              ZipCode: addressNode.ZipCode || "",
              Price: addressNode.Price || 0,
              NumberOfBathrooms: addressNode.NumberOfBathrooms || 0,
              NumberOfBedrooms: addressNode.NumberOfBedrooms || 0,
              SqrFt: addressNode.SqrFt || "",
              BrokerCommission: addressNode.BrokerCommission || "",
              CompensationType: addressNode.CompensationType || "",
              PropertyStatus: addressNode.PropertyStatus || "",
              HasBasement: addressNode.HasBasement !== undefined ? addressNode.HasBasement : false,
              HasGarage: addressNode.HasGarage !== undefined ? addressNode.HasGarage : false,
              MLS: addressNode.MLS || "",
              ListingUrl: addressNode.ListingUrl || "",
              ListingUUID: addressNode.ListingUUID || "",
              uid: addressNode.uid || ""
            }
    })
    await db_driver.close()
    return housingDetailsList
        
    }catch(e){
        await db_driver.close()
        console.error(e)
    }

}
const geProfileByUidQuery = `
MATCH (user:User {uid: $uid})-[:HAS_PROFILE]->(profile:Profile)
RETURN profile`

export const getCurrentProfileN4j = async (uuid) => {
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        const { records } = await db_driver.executeQuery(geProfileByUidQuery, {uid: `${uuid}`})
        const profileInfo = records.map(record => {
            const profileNode = record.get('profile').properties; // Directly accessing properties
      
            // Map the properties to the HousingDetails structure, with default values if any property is missing
            return {
              FirstName: profileNode.FirstName || "",
              LastName: profileNode.LastName || "",
              PhoneNumber: profileNode.PhoneNumber || "",
            }
    })
    await db_driver.close()
    return profileInfo
        
    }catch(e){
        await db_driver.close()
        console.error(e)
    }

}

const getAllMyListingsQuery = `
MATCH (a:Address)
WHERE a.uid = $uid
RETURN a`

export const getMyCurrentListingsN4j = async (uid) => {
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
       
        const { records } = await db_driver.executeQuery(getAllMyListingsQuery, {uid})
        const housingDetailsList = records.map(record => {
            const addressNode = record.get('a').properties; // Directly accessing properties
      
            // Map the properties to the HousingDetails structure, with default values if any property is missing
            return {
              Address: addressNode.Address || "",
              FullAddress: addressNode.FullAddress || "",
              State: addressNode.State || "",
              City: addressNode.City || "",
              ZipCode: addressNode.ZipCode || "",
              Price: addressNode.Price || 0,
              NumberOfBathrooms: addressNode.NumberOfBathrooms || 0,
              NumberOfBedrooms: addressNode.NumberOfBedrooms || 0,
              SqrFt: addressNode.SqrFt || "",
              BrokerCommission: addressNode.BrokerCommission || "",
              CompensationType: addressNode.CompensationType || "",
              PropertyStatus: addressNode.PropertyStatus || "",
              HasBasement: addressNode.HasBasement !== undefined ? addressNode.HasBasement : false,
              HasGarage: addressNode.HasGarage !== undefined ? addressNode.HasGarage : false,
              MLS: addressNode.MLS || "",
              ListingUrl: addressNode.ListingUrl || "",
              ListingUUID: addressNode.ListingUUID || "",
              uid: addressNode.uid || ""
            }
    })
    await db_driver.close()
    return housingDetailsList
        
    }catch(e){
        await db_driver.close()
        console.error(e)
    }

}


export const SearchN4j = async (searchQuery) => {
    const query = `
            MATCH (a:Address)
            WHERE toLower(a.FullAddress) CONTAINS toLower($searchQuery)
            RETURN a
        `
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        
        const { records } = await db_driver.executeQuery(query, {searchQuery})
        const housingDetailsList = records.map(record => {
            const addressNode = record.get('a').properties; // Directly accessing properties
      
            // Map the properties to the HousingDetails structure, with default values if any property is missing
            return {
              Address: addressNode.Address || "",
              FullAddress: addressNode.FullAddress || "",
              State: addressNode.State || "",
              City: addressNode.City || "",
              ZipCode: addressNode.ZipCode || "",
              Price: addressNode.Price || 0,
              NumberOfBathrooms: addressNode.NumberOfBathrooms || 0,
              NumberOfBedrooms: addressNode.NumberOfBedrooms || 0,
              SqrFt: addressNode.SqrFt || "",
              BrokerCommission: addressNode.BrokerCommission || "",
              CompensationType: addressNode.CompensationType || "",
              PropertyStatus: addressNode.PropertyStatus || "",
              HasBasement: addressNode.HasBasement !== undefined ? addressNode.HasBasement : false,
              HasGarage: addressNode.HasGarage !== undefined ? addressNode.HasGarage : false,
              MLS: addressNode.MLS || "",
              ListingUrl: addressNode.ListingUrl || "",
              ListingUUID: addressNode.ListingUUID || "",
              uid: addressNode.uid || ""
            }
    })
    db_driver.close()
    return housingDetailsList
        
    }catch(e){
        db_driver.close()
        console.error(e)
    }

}
export const AdvSearchN4j = async (searchParams) => {
    // console.log(searchParams)
    const queryLoggedIn = `
    MATCH (s:State)
    WHERE toLower(s.Name) = toLower($State)
    WITH s
    MATCH (s)<-[:IN]-(c:City)
    WHERE $City = "" OR toLower(c.Name) = toLower($City)
    WITH c
    MATCH (c)<-[:IN]-(z:ZipCode)
    WHERE $ZipCode = "" OR z.ZipCode = $ZipCode
    WITH z
    MATCH (z)<-[:IN]-(a:Address)
    WHERE
      ($Address = "" OR toLower(a.Address) CONTAINS toLower($Address))
      AND ($State = "" OR a.State = $State)
      AND ($ZipCode = "" OR a.ZipCode = $ZipCode)
      AND ($NumberOfBathrooms = "" OR a.NumberOfBathrooms = $NumberOfBathrooms)
      AND ($NumberOfBedrooms = "" OR a.NumberOfBedrooms = $NumberOfBedrooms)
      AND ($HasBasement = "" OR a.HasBasement = $HasBasement)
      AND ($HasGarage = "" OR a.HasGarage = $HasGarage)
      AND ($MLS = "" OR a.MLS = $MLS)
      AND ($MinPrice = "" OR a.Price >= $MinPrice)
      AND ($MaxPrice = "" OR a.Price <= $MaxPrice)
    RETURN a
    `
 
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        
        const { records } = await db_driver.executeQuery(queryLoggedIn, searchParams)
        // console.log(searchParams)
        if(records.length > 0)
        {
                
                const housingDetailsList = records.map(record => {
                const addressNode = record.get('a').properties; // Directly accessing properties
        
                // Map the properties to the HousingDetails structure, with default values if any property is missing
                return {
                    Address: addressNode.Address || "",
                    FullAddress: addressNode.FullAddress || "",
                    State: addressNode.State || "",
                    City: addressNode.City || "",
                    ZipCode: addressNode.ZipCode || "",
                    Price: addressNode.Price || 0,
                    NumberOfBathrooms: addressNode.NumberOfBathrooms || 0,
                    NumberOfBedrooms: addressNode.NumberOfBedrooms || 0,
                    SqrFt: addressNode.SqrFt || "",
                    BrokerCommission: addressNode.BrokerCommission || "",
                    CompensationType: addressNode.CompensationType || "",
                    PropertyStatus: addressNode.PropertyStatus || "",
                    HasBasement: addressNode.HasBasement !== undefined ? addressNode.HasBasement : false,
                    HasGarage: addressNode.HasGarage !== undefined ? addressNode.HasGarage : false,
                    MLS: addressNode.MLS || "",
                    ListingUrl: addressNode.ListingUrl || "",
                    ListingUUID: addressNode.ListingUUID || "",
                    uid: addressNode.uid || ""    
                }
        })
        db_driver.close()
        // console.log(housingDetailsList.entries())
        return housingDetailsList
    }else{
        db_driver.close()
    }
    // console.log("No Records Found")
        
    }catch(e){
        db_driver.close()
        console.error(e)
    }

}
export const AdvSearchN4jNotLoggedIn = async (searchParams) => {
    const queryNotLoggedIn = `
    MATCH (s:State)
    WHERE toLower(s.Name) = toLower($State)
    WITH s
    MATCH (s)<-[:IN]-(c:City)
    WHERE $City = "" OR toLower(c.Name) = toLower($City)
    WITH c
    MATCH (c)<-[:IN]-(z:ZipCode)
    WHERE $ZipCode = "" OR z.ZipCode = $ZipCode
    WITH z
    MATCH (z)<-[:IN]-(a:Address)
    WHERE
      ($Address = "" OR toLower(a.Address) CONTAINS toLower($Address))
      AND ($State = "" OR a.State = $State)
      AND ($ZipCode = "" OR a.ZipCode = $ZipCode)
    RETURN a.City, a.State, a.ZipCode, a.BrokerCommission
    
    `
    const  db_driver = neo4j.driver(URI,  neo4j.auth.basic(USER, PASSWORD))
    try{
        // console.log(searchParams)
        const { records } = await db_driver.executeQuery(queryNotLoggedIn, searchParams)
       
        if(records.length > 0)
        {
                
            const housingDetailsList = records.map(record => {
                const addressNode = record; // Since you're returning specific fields, record itself will contain those fields directly
            //   console.log(record)
                // Map the properties to the HousingDetails structure, with default values if any property is missing
                return {
                //   Address: addressNode.get('a.Address') || "",
                  City: addressNode.get('a.City') || "",
                  State: addressNode.get('a.State') || "",
                  ZipCode: addressNode.get('a.ZipCode') || "",
                //   FullAddress: addressNode.get('a.FullAddress') || "",
                //   Price: addressNode.get('a.Price') || 0,
                //   NumberOfBathrooms: addressNode.get('a.NumberOfBathrooms') || "",
                //   NumberOfBedrooms: addressNode.get('a.NumberOfBedrooms') || "",
                //   SqrFt: addressNode.get('a.SqrFt') || "",
                    BrokerCommission: addressNode.get('a.BrokerCommission') || "",
                    // BrokerCommission: addressNode.BrokerCommission || "",
                    // CompensationType: addressNode.CompensationType || "",
                    // PropertyStatus: addressNode.PropertyStatus || "",
                //   HasBasement: addressNode.get('a.HasBasement') !== undefined ? addressNode.get('a.HasBasement') : false,
                //   HasGarage: addressNode.get('a.HasGarage') !== undefined ? addressNode.get('a.HasGarage') : false,
                //   MLS: addressNode.get('a.MLS') || "",
                //   ListingUrl: addressNode.get('a.ListingUrl') || "",
                //   ListingUUID: addressNode.get('a.ListingUUID') || "",
                //   uid: addressNode.get('a.uid') || ""
                }
        })
        db_driver.close()
        // console.log(housingDetailsList.entries())
        return housingDetailsList
    }else{
        db_driver.close()
    }
    // console.log("No Records Found")
        
    }catch(e){
        db_driver.close()
        console.error(e)
    }

}

// export const DbClose = async () => {
//     db_session.close()
//     db_driver.close()
// }
import { auth, db } from './config/fb-config'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { getDoc, updateDoc, doc, setDoc, getDocs, collection } from 'firebase/firestore'
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { StatesArray, CommissionArray } from './Objs'
// import { generateClient } from 'aws-amplify/api-rest'
import { Amplify } from 'aws-amplify'
import axios from 'axios'
import awsExports from './aws-api-export'
import {saveUserInfoN4j, getCurrentProfileN4j} from './config/neo4j-db'
Amplify.configure(awsExports)

export const Sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  )

  export const prepareSMSMsgAndSend = async (propertyInfo) => {
    const profileInfo = await GetProfile(auth.currentUser.uid)
    // console.log(auth.currentUser.uid)
    // console.log(profileInfo)
    const sms = {
        to: `+1${profileInfo.PhoneNumber}`,
        message: `${propertyInfo.FullAddress}\nsqft: ${propertyInfo.SqrFt} ${propertyInfo.NumberOfBedrooms} bd\n${propertyInfo.NumberOfBathrooms} ba\nBasement: ${propertyInfo.HasBasement? 'Yes' : 'No'}\nGarage: ${propertyInfo.HasGarage ? 'Yes' : 'No'}\nPrice: ${formatCurrency(propertyInfo.Price)}\nCompensation:
        ${propertyInfo.CompensationType === '999'
            ? 'No Compensation Offered'
            : propertyInfo.CompensationType === '998'
            ? formatCurrency(propertyInfo.BrokerCommission) 
            : propertyInfo.CompensationType === '997'
            ? Number(propertyInfo.BrokerCommission) + '%' : "N/A" }`
        // message: "this is a test"
        } 
    await sendSMS(sms)
  }

  export const sendSMS = async (sms) => {
    
    // const { to, message} = sms
    const apiName = 'sendSMS' // Replace with your actual API name
    const path = '/sendSMS'
    const apiKey = process.env.REACT_APP_SEND_SMS_API_KEY
    const apiEndpoint = awsExports.aws_cloud_logic_custom.find(api => api.name === apiName).endpoint + path
    // console.log(sms)
    try {
        const response = await axios.post(apiEndpoint, { body:{
                to: sms.to,
                message: sms.message,
                apiKey: apiKey // Include the API key in the request body
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            }
        });

        const {success} = JSON.parse(response.data.body)
        if (success === true) {
            console.log('SMS sent successfully');
        } else {
            console.log('Failed to send SMS');
            console.log(response)
        }
    } catch (error) {
        // console.log('Error sending SMS');
        // console.log(JSON.stringify(sms, null,2))
        console.error(error)
    }
}

export const StateSelection = ({className, onChange, disabled, selectedState}) => {
    return (
        <select name="State"  value={selectedState} className={className} onChange={onChange} disabled={disabled} >
            {
                StatesArray.map(state => (
                            <option key={state.value} value={state.value}>{state.label}</option>
                        ))
            }
        </select>
    )
}
export const CommissionSelection = ({name, className, onChange, disabled, selectedCommision}) => {
    return (
        <select name={name}  value={selectedCommision} className={className} onChange={onChange} disabled={disabled} >
            {
                CommissionArray.map(commission => (
                            <option key={commission.value} value={commission.value}>{commission.label}</option>
                        ))
            }
        </select>
    )
}

export function isValidUSZipCode(zipCode) {

    if(zipCode === "")
        return true
    // Regular expression to check if the ZIP code matches either 5 digits or 5 digits followed by a hyphen and 4 more digits
    const zipCodePattern = /^[0-9]{5}(-[0-9]{4})?$/
    
    return zipCodePattern.test(zipCode)
}

export const formatCurrency = (amount, currency = 'USD', locale = 'en-US') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(amount)
  }

export const UpdatePropertyListing = async ( propertyInfo ) => {
    try{
        //Todo check for updates to the profile        
        const listingInfo = await getDoc(doc(db,'listings', propertyInfo.ListingUUID))
        const date = new Date()
        const ticks = dateToTicks(date)
        if(!listingInfo.exists())
        {
            await setDoc(doc(db,'listings', propertyInfo.ListingUUID),{...propertyInfo, uid: auth?.currentUser?.uid, created: `${ticks}`})
        }
        else
        {
            // console.log(propertyInfo)
            await updateDoc(listingInfo.ref,{...propertyInfo, updated: `${ticks}`})
        }
        return true
    }catch (err){
        console.error(err)
        return false
    }

}
export const CreateAccount = async (authInfo) => {
    let err = null
    try{
        if(authInfo.password !== authInfo.passwordConf)
        {
            alert("Password does not match")
          
        }
        await createUserWithEmailAndPassword(auth,authInfo.email, authInfo.password) // validate signup
        await CreateProfile(authInfo)

    }catch (e){
        console.error(e)
        err = e
    }
    return err
}

export const CreateProfile = async (authInfo) => {
    try{
        console.log(authInfo)
        await saveUserInfoN4j(authInfo)
    }catch(e){

    }
}

export const GetProfile = async (uid) => {
    try{
        const profileInfo = await getCurrentProfileN4j(auth.currentUser.uid)
        return profileInfo[0]
    }catch(e){
        console.error(e)
    }
    
}

export const signIn = async (authInfo) => {
    try{
        const userCred =  await signInWithEmailAndPassword(auth, authInfo.email, authInfo.password)
        return userCred  
    }catch(e){
        console.error(e)
    }
}

export const signOut = async () => {
    try{  
        await auth.signOut()
        return true
    }catch(e){
        console.error(e)
        return false
    }
}

export  const getCurrentListings = async () => {
    try{
        // const colRef = collection(db,'listings',"OH","45459")
        const colRef = collection(db,'listings')
        const docSnapshot = await getDocs(colRef)
        return { data: docSnapshot.docs }
    }catch (err){
      console.error(err)
  }

}

export const dateToTicks = ( date ) => {
    const epochOffset = 621355968000000000
    const ticksPerMillisecond = 10000
  
    const ticks =
      date.getTime() * ticksPerMillisecond + epochOffset
  
    return ticks
}
export const convertTicksToDate = ( ticks ) => {
    // .NET ticks start from 0001-01-01, while JavaScript dates start from 1970-01-01.
    // The difference in ticks needs to be accounted for.
    const ticksSince1970 = ticks - 621355968000000000
    // Convert ticks to milliseconds (1 tick = 100 nanoseconds)
    const milliseconds = ticksSince1970 / 10000
    return new Date(milliseconds)
}
export const addCommasToNumber = (input) => {
   // Check if the input is neither a string nor a number
  if (typeof input !== 'string' && typeof input !== 'number') {
    throw new Error('Input must be a number or a string representing a number')
  }

  // Convert the input to a number
  const number = Number(input)

  // Check if the conversion to a number is valid
  if (isNaN(number)) {
    throw new Error('Input must be a valid number or a string representing a valid number')
  }

  // Convert the number to a string and format it with commas
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const validateAndFormatNumber = (input) => {
    // Check if the input is a number (including floating-point)
    if (!isNaN(parseFloat(input)) && isFinite(input)) {
        // Convert the input to a number (to handle inputs like '3.1400' or '002')
        const number = Number(input)

        // Check if the number is floating-point by comparing it to its integer conversion
        if (number !== parseInt(number, 10)) {
            // If it's a floating point, limit to 2 decimal places
            return number.toFixed(2)
        } else {
            // If it's an integer, return as is
            return number
        }
    } else {
        // If input is not a number, return -1
        return -1
    }
}

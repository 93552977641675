import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Search from './Search';
import SignUp from './SignUp';
import Login from './Login';
import TermsOfService from './TermsOfService';
// import Hero from './Hero';
import EditListing from './EditListing';
import Listings from './MyListings';
import LandingPage from './LandingPage';
import { signOut } from './Misc';
import { auth } from './config/fb-config';
import './Menu.css'; // Make sure to import the CSS file
import logo from './logo.png'; // Update this to your banner image

export default function Menu() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <header className="header">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} className='logo' alt="Logo" />
          </Link>
        </div>
        <div className="container">
          <nav className="nav">
            <div className="nav-links">
              {!isLoggedIn ? (
                <>
                  <Link to="/Login" className="nav-link">
                    <div className="btn-primary">
                      Sign-In
                    </div>
                  </Link>
                  <Link to="/SignUp" className="nav-link">
                    <div className="btn-primary">
                      Sign Up Now
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/CreateListing" className="nav-link">
                    <div className="btn-primary">
                      Create Listing
                    </div>
                  </Link>
                  <Link to="/Search" className="nav-link">
                    <div className="btn-primary">
                      Search
                    </div>
                  </Link>
                  <Link to="/MyListings" className="nav-link">
                    <div className="btn-primary">
                      My Listings
                    </div>
                  </Link>
                  <div className="nav-link">
                    <div className="btn-primary" onClick={() => {
                      signOut();
                    }}>
                      Sign-Out
                    </div>
                  </div>
                </>
              )}
            </div>
          </nav>
        </div>
      </header>
      <Routes>
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/BuyerLogin" element={<Login />} />
        <Route exact path="/SignUp" element={<SignUp />} />
        <Route exact path="/Search" element={<Search />} />
        <Route exact path="/AgentLogin" element={<Login />} />
        <Route exact path="/CreateListing" element={<EditListing />} />
        <Route exact path="/MyListings" element={<Listings />} />
        <Route exact path="/TermsOfService" element={<TermsOfService />} />
        {/* <Route exact path="/" index element={<Hero />} /> */}
        <Route exact path="/" index element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { signIn } from './Misc';
import './Login.css'; // Import the CSS file

export default function Login() {
  const loginInfo = {
    email: "",
    password: ""
  };

  const [authInfo, setAuthInfo] = useState(loginInfo);
//   const [portalType, setPortalType] = useState('Buyer');
  const navigate = useNavigate();

  const updateAuthInfo = (e) => {
    const { name, value } = e.target;
    setAuthInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const userLogin = async (authInfo) => {
    const userCred = await signIn(authInfo);
    if (userCred)
      navigate("/");
  };

  return (
    <div className="login-container">
      {/* <div className="login-header">Sign In</div> */}
      <div className="login-form">
        <div className="login-card">
          {/* <div className="login-card-header">{portalType} Portal</div> */}
          <div className="login-card-header">Sign-In</div>
          <div><input className="login-input" type="email" placeholder="Email address" name="email" onChange={updateAuthInfo} value={authInfo.email} /></div>
          <div><input className="login-input" type="password" placeholder="Password" name="password" onChange={updateAuthInfo} value={authInfo.password} /></div>
          <div>
            <button className="login-button" onClick={() => userLogin(authInfo)}>Sign-In</button>
          </div>
          <div className="login-links">
            <div className="login-link-item">
              <div>Not yet registered?</div>
              {/* <div><Link to='/SignUp' className="link">SIGN UP HERE</Link></div> */}
              <div><Link to='/SignUp' className="link">SIGN UP HERE</Link></div>
            </div>
            <div className="login-link-item">
              <div>Forgot your password?</div>
              <div><Link to='/' className="link">RESET PASSWORD</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React ,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {auth} from './config/fb-config'
import { getMyCurrentListingsN4j } from './config/neo4j-db'
import { formatCurrency } from './Misc'
import './Search.css'


export default function Listings() {

const [listings, setListings] = useState([])


useEffect(() => {
  const getListings = async ()=> {
    if(auth.currentUser?.uid)
    {
      const dbListings = await getMyCurrentListingsN4j(auth.currentUser.uid)
      setListings(dbListings)
    }
  // console.log(dbListings)
  
  }
 getListings()
  
}, [])

  return (
    <>
      <div className="results">
        <div>
            {
              listings.map((info, i) => (
              <div  className="listing" key={i}>
                  <div key={i} className="listing-details">
                    <div>
                      <div>{info.Address.split(',')[0]}</div>
                      <div  className="additional-details">
                        <div>{info.City},</div> 
                        <div>{info.State}</div> 
                        <div>{info.ZipCode}</div>
                      </div> 
                    </div>
                    <div className="additional-details">
                      <div>{info.SqrFt} sqft</div>  
                      <div>{info.NumberOfBedrooms} bd</div> 
                      <div>{info.NumberOfBathrooms} ba</div>
                    </div> 
                    <div className="additional-details">
                      <div>Basement: {info.HasBasement ? 'Yes' : 'No'}</div>
                      <div>Garage: {info.HasGarage ? 'Yes' : 'No'}</div>
                    </div>
                    <div className="additional-details">Price {formatCurrency(info.Price)}</div>
                    <div className="additional-details">
                      <>
                          Buyer Broker Compensation:
                          <span>
                            {info.BrokerCommission === '999'
                              ? 'No Compensation Offered'
                              : info.BrokerCommission === '998'
                              ? formatCurrency(info.FlatFee)
                              : info.BrokerCommission + ' - ' + (Number(info.BrokerCommission) + 1) + '%'}
                          </span>
                        </>
                      </div>
                    {/* <div>
                      { info.MLS ?
                        <div>MLS# {info.MLS}</div>
                        :
                        ""
                      }
                    </div> */}
                      <Link
                            to="/CreateListing"
                            state={{ info: info }}
                            style={{ pointerEvents: auth.currentUser ? '' : 'none', textDecoration: 'none' }}
                      >
                        <div className='btn-primary mt-3'>Edit My Listing</div>
                      </Link>
                    <div>
                    {/* {info.ListingUrl} */}
                      {/* { info.ListingUrl ?
                        <a href={info.ListingUrl} target="_blank" >View Listing</a>
                      :
                        ""
                      }  */}
                    </div> 
                    
                  </div>
              
              </div>
            
            ))
            
            }
        </div>
      </div>
    </>
  )
}

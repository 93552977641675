import React from 'react';
import './LandingPage.css';
import heroImage from './hero-image.png';

const LandingPage = () => {
    return (
        <div>
            {/* <header className="header">
                <h1>BuyerBrokersNetwork.com</h1>
            </header> */}
            <div className='hero'> 
                {/* <image src={heroImage} className="hero" /> */}
                <img src={heroImage} alt='' />
            </div>
            
            <div className="hero-content">
                {/* <h1>Welcome to BuyerBrokersNetwork.com</h1> */}
                <p>Starting August 17, 2024, real estate transactions will undergo a significant change. Realtors will no longer be able to list the commission compensation for buyer agents in the Multiple Listing Service (MLS). To address this change and ensure a smooth, transparent process for everyone involved, we have created this platform.</p>
                {/* <a href="#" className="button">Sign Up Now</a> */}
            </div>
            <div className="container">
                <section className="features">
                    <div className="feature">
                        <h3>Streamlined Compensation inquiry</h3>
                        <p>Our website allows realtors and sellers to inquire about the agreed-upon compensation for buyer agents directly. This ensures that all parties have access to essential information, helping to facilitate the goal of selling the property.</p>
                    </div>
                    <div className="feature">
                        <h3>Unified Goals</h3>
                        <p>We understand that sellers, buyers, and realtors share a common objective: to achieve a successful property sale. Our platform is designed to support this goal by providing a clear and efficient way to handle commission details.</p>
                    </div>
                    <div className="feature">
                        <h3>Transparency and Efficiency</h3>
                        <p>By centralizing the request of buyer agent compensation, we eliminate confusion and promote transparency in real estate transactions. This not only benefits the sellers and buyers but also supports realtors in delivering exceptional service.</p>
                    </div>
                </section>
            </div>
            <footer className="footer">
                <p>Be ready for the changes coming on August 17, 2024. Join BuyerBrokersNetwork.com and experience a smoother, more efficient way to handle real estate transactions.</p>
            </footer>
        </div>
    );
};

export default LandingPage;

import React from 'react'
import Menu from './Menu';
import './MainLayout.css'
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  return (
    <>
        <div className='layout-container'>
            <div>
                <Menu />
            </div>
            <div>
                <Outlet />
            </div>
        </div>
    </>
  )
}
